<template>
  <div class="pwdSetting">
    <!-- pwdSetting 密码设置 -->
    <div class="nav">
      <div class="nav_title">消费密码设置</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt="" width="15" height="25">
      </div>
    </div>
    <div class="pwdSetting-main">
      <van-form ref="form">
        <div class="pwdSetting-main-title">设置新的消费支付密码</div>
        <van-field
          v-model="pwd"
          class="pwdSetting-main-input"
          type="password"
          placeholder="4位数字，默认密码是手机号后四位"
        />
      </van-form>
      <button class="confirm" @click="confirm">确认</button>
      <div class="tips">
        提示：消费密码生效有一定的延迟
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png'
import { getAccInfo } from '@/api/person'
// import { getAccInfo, getPassword } from '@/api/person'
import { Toast } from 'vant'
import { getPassword } from '../../api/person'

export default {
  data() {
    return {
      cancel,
      pwd: '****',
      userId: null,
      password: ''
      // validator(val) {
      //   return /^\[0-9]{4}$/.test(val)
      // }
      // passwordRules: [
      //   // {
      //   //   required: true,
      //   //   message: '新密码不能为空',
      //   //   trigger: 'onBlur'
      //   // },
      //   {
      //     // 自定义校验规则
      //     validator: (value) => {
      //       return /^\[0-9]{4}$/.test(
      //         value
      //       )
      //     },
      //     message: '请输入正确格式的新密码',
      //     trigger: 'onBlur'
      //   }
      // ]
    }
  },
  created() {
    this.getInfoList()
  },
  methods: {
    toBack() {
      this.$router.back()
    },
    // 获取用户信息
    async getInfoList() {
      const res = await getAccInfo()
      // console.log(res.data)
      this.userId = res.data.id
      // this.password = res.data.accessPwd
    },
    async confirm() {
      // console.log('确认')
      // console.log(this.userId, this.pwd)
      // console.log(/^[0-9]*$/.test(this.pwd))
      if (this.pwd.length === 4 && /^[0-9]*$/.test(this.pwd)) {
        // console.log(this.pwd)
        const res = getPassword({
          userId: this.userId,
          password: this.pwd
        })
        console.log(res)
        Toast.success('修改成功')
      } else {
        Toast.fail('请输入4位数字密码')
      }
      // this.$refs.form.validate(async(validator) => {
      //   if (validator) {
      //     const res = await getPassword({
      //       userId: this.userId,
      //       password: this.pwd
      //     })
      //     console.log(res.message)
      //     if (res.code === 0) {
      //       Toast.success('修改成功')
      //     }
      //   }
      // })
      // Toast.success('修改成功')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-field__body {
  display: block;
  box-sizing: border-box;
  padding: 0 5px;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid rgba(181, 201, 210, 0.6);
}
.pwdSetting {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .pwdSetting-main {
    flex: 1;
    // box-sizing: border-box;
    padding: 4rem 1rem 0 1rem;
    .pwdSetting-main-title {
      margin-bottom: 1.5rem;
    }
    .pwdSetting-main-input {
      width: 100%;
      // margin-bottom: 1.5rem;
      box-sizing: border-box;
      // padding-left: 1rem;
      height: 5rem;
      // line-height: 2.5rem;
      // border: 1px solid #d2d2d2;
      outline: none;
      border-radius: 5px;
      // padding-bottom: 1rem;
    }
    // .hint {
    //   font-size: 0.75rem;
    //   line-height: 1.2rem;
    //   text-align: center;
    //   box-sizing: border-box;
    //   padding: 0 10px;
    //   margin-bottom: 1.5rem;
    //   color: #607483;
    // }
    .confirm {
      color: #fff;
      height: 2.5rem;
      border: 0;
      width: 100%;
      line-height: 2.5rem;
      text-align: center;
      box-sizing: border-box;
      border-radius: 8px;
      background: #db2020;
    }
    .tips {
      margin: 2.5rem auto;
      width: 17rem;
      font-size: 1.1rem;
    }
  }
}
</style>
